<script>
import NotificationNewView from './NotificationNewView'

export default {
  name: 'NotificationEditView',
  extends: NotificationNewView,
  methods: {
    async getNotification () {
      await this.$store.dispatch('notificationService/fetchOne', this.$route.params.id)
      this.notification = this.$store.getters['notificationService/detail']
    }
  },
  created () {
    this.getNotification()
  }
}
</script>
