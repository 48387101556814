<template>
  <NotificationEditView disabled />
</template>

<script>
import NotificationEditView from '@/views/notificationService/notifications/NotificationEditView'

export default {
  name: 'NotificationView',
  components: {
    NotificationEditView
  }
}
</script>
